import React, { useMemo, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import {
    Container,
    Box,
    Table,
    TableContainer,
    Tbody,
    Thead,
    Tr,
    Td,
    Th,
    Heading
} from '@chakra-ui/react'


export default function Stats2016() {

    const API = `https://sheets.googleapis.com/v4/spreadsheets/1WxY6fpYtmLbFBPl0giyRDdMrLmo74DaHs88QKyF_TGA/values:batchGet?ranges=2016-2017&majorDimension=ROWS&key=${process.env.GATSBY_GOOGLE_SHEETS}`
    
    const [ teamData, setTeamData ] = useState([]);
    
useEffect(() => {
    fetch(API).then(response => response.json())
    .then(tableData => setTeamData(tableData.valueRanges[0].values))
}, []);

teamData.shift(); // remove header row

const data = useMemo(() => {
    return teamData.map(team => {
        return {
            number: team[0],
            name: team[1],
            gp: team[2],
            min: team[3],
            w: team[4],
            l: team[5],
            t: team[6],
            sog: team[7],
            sv: team[8],
            ga: team[9],
            gaa: team[10],
            svpercent: team[11],
            so: team[12],
        }}
           );
        }, [teamData]); // dependancy array required to avoid re-rendering infinite loop
    
    const columns = useMemo(
        () => [
            {
                Header: 'Number',
                accessor: 'number', // accessor is the "key" in the data
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'GP',
                accessor: 'gp',
            },
            {
                Header: 'Min',
                accessor: 'min',
            },
            {
                Header: 'W',
                accessor: 'w',
            },
            {
                Header: 'L',
                accessor: 'l',
            },
            {
                Header: 'T',
                accessor: 't',
            },
            {
                Header: 'SOG',
                accessor: 'sog',
            },
            {
                Header: 'SV',
                accessor: 'sv',
            },
            {
                Header: 'GA',
                accessor: 'ga',
            },
            {
                Header: 'GAA',
                accessor: 'gaa',
            },
            {
                Header: 'SV%',
                accessor: 'svpercent',
            },
            {
                Header: 'SO',
                accessor: 'so',
            },
        ],[] // dependancy array required to avoid re-rendering infinite loop
    )

    const tableInstance = useTable({ columns, data });

    const { 
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <Box my="8">
            <Heading as="h3" mb="8">
                2016-2017 Team Stats
            </Heading>

            <TableContainer>
            <Table {...getTableProps()} variant="stats-table">
                <Thead>
                    {headerGroups.map(headerGroup => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <Th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <>
                                       {cell.row.values.number?.includes("#") || cell.row.values.number?.includes("Number") ? (
                                        <Td {...cell.getCellProps()} style={{fontWeight: 'bold'}}>
                                            {cell.render('Cell')}
                                        </Td>
                                       )
                                       : (
                                        <Td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </Td>
                                       )}
                                    </>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
            </TableContainer>
        </Box>
    )
}