import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

import PageTitle from '../PageTitle/PageTitle'
import {
    Box,
    Flex,
    Heading,
    Container,
    Text
} from '@chakra-ui/react'

export function TeamMember(props) {
    return (
        <Box w={{base: '100%', md: '33%', lg: '25%'}} p="4">
        <Flex flexDirection="column" justifyContent="space-around" h="100%" bg="brand.600" p="2">            
            <Box w="100%">
                <GatsbyImage image={getImage(props.playerImage)} alt={props.alt} width="100%"/>
                {/* <StaticImage src="../../images/player.jpg" alt="sample player" /> */}
            </Box>
            <Box position="relative">
                <Text color="white" fontSize="1.5rem" marginBottom="0" fontWeight="700">
                    {props.playerName}
                </Text>
                <Text color="white" fontSize="1.5rem" marginBottom="0">
                    {props.playerPosition}
                </Text>
                <Text color="white" fontSize="1.25rem" marginBottom="0">
                    Height: {props.playerHeight}
                </Text>
                <Text color="white" fontSize="1.25rem" marginBottom="0">
                    Weight: {props.playerWeight}
                </Text>
                <Box position="absolute" right="0" bottom="-8">
            <Text color="white" fontSize="2rem" fontWeight="700">#{props.playerNumber}</Text>
        </Box>
            </Box>
        </Flex>
    </Box>
    )
}

const TeamRoster = () => {
    const data = useStaticQuery(graphql`
    {
      allPrismicTeamRoster {
        edges {
          node {
            data {
              player {
                weight {
                  text
                }
                player_position {
                  text
                }
                player_photo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        aspectRatio: 1
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                  alt
                }
                player_number
                player_name {
                  text
                }
                height {
                  text
                }
              }
              roster_title {
                text
              }
            }
          }
        }
      }
    }
  `)

  const players = data.allPrismicTeamRoster.edges[0].node.data.player;

    return (
        <Box bg="black" h="100%">
            <Container maxW="container.xl">
          
            <PageTitle title={data.allPrismicTeamRoster.edges[0].node.data.roster_title.text} color="white"/>
            
            <Box pb="20">
                <Flex w="100%" flexWrap="wrap" justifyContent="space-between">
                    {players.map((player) => {
                        return (                            
                            <TeamMember 
                                playerImage={player.player_photo.localFile}
                                playerName={player.player_name.text}
                                playerPosition={player.player_position.text}
                                playerHeight={player.height.text}
                                playerWeight={player.weight.text}
                                playerNumber={player.player_number}
                            />
                        )
                    })}
                </Flex>
            </Box>
            </Container>
        </Box>
    )
}

export default TeamRoster;