import React from "react";
import styled from "@emotion/styled";
import Ice from "../../images/Ice.jpg"
import { 
  Box,
  Heading,
  Center
} from '@chakra-ui/react'
import MooseHockeyLogo from "../MooseHockeyLogo/MooseHockeyLogo";

const IceMask = styled.svg`
  width: 100vw;
  height: 100%;
  .cls-1 {
        fill: none;
      }

      .cls-2 {
        clip-path: url(#clip-path);
      }

      .cls-3 {
        isolation: isolate;
      }

      .cls-4 {
        clip-path: url(#clip-path-2);
      }
  `


const HomeIceMask = (props) => {
  return (
<>
<Box
  top={{sm: '13%', md: '18%', lg: '18%', xl: '20%'}}
  left={{base: '0%', md: '0%', lg: '4%', xl: '4%'}}
  w='70%'
  position="absolute"
  display={{base: 'none', lg: 'block'}}
>
  
    <Box w='40%' ml={{base: '1rem'}}>
      <MooseHockeyLogo width="100%" />
    </Box>
  
  <Box textAlign="center" mt={{base: 8}} width={{base: '100%', md: '40%'}}>
  <Heading as="h1" variant="page-title" fontSize={{base: "1.5rem", lg: "3rem", xl: "4.5rem"}}>
     Jackson Hole Moose
    </Heading>
    <Heading as="h3" color="brand.600" fontSize={{base: "1rem", lg: "2.5rem", xl: "3.5rem"}}>
      -26th Season-
    </Heading>
</Box>
</Box>

<Box
  display={{base: 'block', lg: 'none'}}
  pt="16"
>
  <Center>
    <MooseHockeyLogo width="50%" />
  </Center>
  
  <Box textAlign="center" mt={{base: 8}} width={{base: '100%', lg: '50%'}}>
  <Heading as="h1" variant="page-title" fontSize="2.5rem">
     Jackson Hole Moose
    </Heading>
    <Heading as="h3" color="brand.600" fontSize="1.5rem">
      -26th Season-
    </Heading>
</Box>
</Box>


<Box display={{base: 'none', lg: 'block'}}>
  <IceMask xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 3840 2755.80177">
    <defs>
      <clipPath id="clip-path">
        <path class="cls-1" d="M2155.90649,1054.07861C2255.01,1022.88916,3561.39478,591.692,3840,499.71539V0H0V2755.80177l3840,.00023V2529.59961c-267.36011-90.157-1497.73584-511.99732-2111.926-843.34912C1036.60034,1313.205,2044.9198,1089.00781,2155.90649,1054.07861Z"/>
      </clipPath>
      <clipPath id="clip-path-2">
        <path class="cls-1" d="M1728.07385,1686.25061c614.19007,331.35181,1844.566,753.192,2111.92615,843.349V499.71539c-278.60522,91.97657-1584.99,523.17377-1684.09375,554.36322C2044.9198,1089.00781,1036.60034,1313.205,1728.07385,1686.25061Z"/>
      </clipPath>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="Layer_2-2" data-name="Layer 2">
          <g id="Layer_1-2-2" data-name="Layer 1-2">
            <g id="Layer_2-2-2" data-name="Layer 2-2">
              <g id="Layer_4" data-name="Layer 4">
                <g class="cls-2">
                  <image class="cls-3" width="3840" height="2501" transform="scale(1 1.10188)" xlinkHref={Ice}/>
                </g>
              </g>
              <g id="Layer_1-2-2-2" data-name="Layer 1-2-2">
                <path d="M1605.01233,1691.81323C2319.177,2076.96118,3840,2580.52979,3840,2580.52979v-50.93018c-267.36011-90.157-1497.73584-511.99732-2111.926-843.34912-691.47363-373.04566,316.846-597.2428,427.83252-632.172C2255.01,1022.88916,3561.39478,591.692,3840,499.71539v-50.6726s-1678.48682,554.02728-1793.11548,590.08953C1932.25586,1075.19482,890.84766,1306.66528,1605.01233,1691.81323Z"/>
                <path class="cls-1" d="M1728.07385,1686.25061c614.19007,331.35181,1844.566,753.192,2111.92615,843.349V499.71539c-278.60522,91.97657-1584.99,523.17377-1684.09375,554.36322C2044.9198,1089.00781,1036.60034,1313.205,1728.07385,1686.25061Z"/>
              </g>
            </g>
            <g class="cls-4">
              <image class="cls-3" width="1614" height="1171" transform="translate(1010.26556 400.72348) scale(1.75324)" xlinkHref={props.hockeyImage}/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </IceMask>
</Box>
</>
  );
};

export default HomeIceMask;
