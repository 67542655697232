import React from 'react'
import {
    Box,
    Heading
} from '@chakra-ui/react'

const PageTitle = (props) => {

    return (
        <Box mb="20">
            <Heading pt="20" as="h1" textAlign="center" variant="page-title" color={props.color} fontSize={{base: '2.5rem', md: '3rem', lg: '4rem'}}>{props.title}</Heading>
            <Heading as="h2" variant="sub-title">{props.subTitle}</Heading>
        </Box>
    )
}

export default PageTitle