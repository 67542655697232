import * as React from "react";
import { graphql } from "gatsby";
import { 
  Box,
  Container,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
 } from "@chakra-ui/react";
import { getImage } from "gatsby-plugin-image"
import Seo from "../../components/seo"

import MooseHockeyIceMask from "../../components/IceMasks/MooseHockeyIceMask";
import MooseHockey from "../../components/HomePage/HomeContent/MooseHockey"

import StripeDivder from "../../components/IceMasks/StripeDivider";
import GameSchedule from "../../components/MooseHockeyPage/GameSchedule"
import BackgroundImage from '../../components/Backgrounds/BackgroundImage'
import DonateNewsletterBlock from "../../components/DonateNewsletterBlock/DonateNewsletterBlock";
import Footer from "../../components/Footer/Footer"
import TeamStats from "../../components/MooseHockeyPage/TeamStats";
import TeamRoster from "../../components/MooseHockeyPage/TeamRoster";
import Stats2009 from "../../components/TeamStats/Stats2009";
import Stats2010 from "../../components/TeamStats/Stats2010";
import Stats2011 from "../../components/TeamStats/Stats2011";
import Stats2012 from "../../components/TeamStats/Stats2012";
import Stats2013 from "../../components/TeamStats/Stats2013";
import Stats2014 from "../../components/TeamStats/Stats2014";
import Stats2015 from "../../components/TeamStats/Stats2015";
import Stats2016 from "../../components/TeamStats/Stats2016";
import Stats2017 from "../../components/TeamStats/Stats2017";
import Stats2018 from "../../components/TeamStats/Stats2018";
import Stats2019 from "../../components/TeamStats/Stats2019";
import Stats2020 from "../../components/TeamStats/Stats2020";
import Stats2021 from "../../components/TeamStats/Stats2021";
import Stats2022 from "../../components/TeamStats/Stats2022";
 

const MooseHockeyPage = ({ data }) => {
  if (!data) return null;
  const document = data.allPrismicPage.edges[0].node.data;
  const HeroImage = document.hero_image?.localFile.publicURL;
  //const secondaryImage = document.secondary_hero_image.localFile;
  const backgroundImage = data.allFile.edges[0].node.childImageSharp;

  return (
    <>
    <Seo title="Snow King Sports &amp; Events Center | Moose Hockey" />
      <MooseHockeyIceMask hockeyImage={HeroImage} />
      <StripeDivder />

      <Box backgroundColor="white">
        <Container maxW="container.xl" my="16">
          <Heading as="h2" mb="12">
            Moose Hockey Team Stats
          </Heading>
      
          <Accordion allowToggle>
          <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2022-2023
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2022 />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2021-2022
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2021 />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2020-2021
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2020 />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2019-2020
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2019 />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2018-2019
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2018 />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2017-2018
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2017 />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2016-2017
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2016 />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2015-2016
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2015 />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2014-2015
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2014 />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2013-2014
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2014 />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2012-2013
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2012 />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2011-2012
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2011 />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2010-2011
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2010 />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  2009-2010
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb="8">
                <Stats2009 />
              </AccordionPanel>
            </AccordionItem> 
            </Accordion>

          

        </Container>
        {/* <StripeDivder /> */}

   
        <BackgroundImage
          backgroundImage={getImage(backgroundImage)}>
        <StripeDivder />
        
      
        <DonateNewsletterBlock 
          title="Book Your Private Event On The Ice"
          content="Now, of course not, Biff, now, I wouldn't want that to happen. Well, now we gotta sneak this back into my laboratory, we've gotta get you home. Yeah. I can't play. Just say anything, George, say what ever's natural, the first thing that comes to your mind."
          btnText="Book Today To Reserve Your Spot"
          btnLink="/contact"
        />
        <Footer />
      </BackgroundImage>
      </Box>
    </>
  );
};

export const query = graphql`
  {
    allFile(filter: {relativePath: {eq: "Ice.jpg"}}) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 80)
          }
        }
      }
    }
    allPrismicPage(filter: { uid: { eq: "home" } }) {
      edges {
        node {
          uid
          data {
            hero_image {
              localFile {
                absolutePath
                publicURL
              }
              alt
            }
            secondary_hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                }
              }
            }
            page_title {
              text
            }
          }
        }
      }
    }
  }
`;

export default MooseHockeyPage;
