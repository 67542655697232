import React from "react";
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Heading, Flex, Text, Link } from "@chakra-ui/react";
import PageTitle from "../PageTitle/PageTitle";
import Ticket from "./ticket-duotone.svg";
import Video from "./video-duotone.svg";

export function GameInfo(props) {
  return (
    <Box w={{ sm: "100%", md: "50%" }}>
      <Flex w="100%">
        {/* <Box w="20%">
          <Flex flexWrap="wrap">
              <Box w={{md: '75%', lg: '50%'}} p="2">
                  <Link
                    href={props.videoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Video} alt="" width="40px" />
                  </Link>
              </Box>
              <Box w={{md: '75%', lg: '50%'}} p="2">
                  <Link
                    href={props.ticketLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Ticket} alt="" width="40px" />
                  </Link>
              </Box>
          </Flex>
        </Box> */}
        <Box p="2" w="80%">
          <Heading as="h4" fontFamily="lato" fontSize="1.75rem">
            {`${props.gameDates} v. ${props.opponent}`}
          </Heading>
          <Text mb="0">{props.bandName}</Text>
        </Box>
      </Flex>
    </Box>
  );
}

const GameSchedule = () => {
    const data = useStaticQuery(graphql`
    {
      allPrismicGameSchedule(
        sort: {fields: data___game___day_of_first_game, order: DESC}
      ) {
        edges {
          node {
            data {
              game {
                video_link {
                  url
                }
                tickets_link {
                  url
                }
                opponent {
                  text
                }
                game_dates {
                  text
                }
                day_of_first_game
                band_name {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  const document = data.allPrismicGameSchedule.edges[0].node.data.game
  
  return (
    <>
      <PageTitle title="Snow King SEC Game Schedule 2022-23" subTitle="" fontSize={{}}/>
      <Box mb="20">
        <Flex w="100%" flexWrap="wrap">
            {document.map((game) => {
                return (
                    <GameInfo 
                        videoLink={game.video_link.url}
                        ticketLink={game.tickets_link.url}
                        gameDates={game.game_dates.text}
                        opponent={game.opponent.text}
                        bandName={game.band_name.text}
                    />
                )
            })}
        </Flex>
      </Box>
    </>
  );
};

export default GameSchedule;
