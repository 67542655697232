import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import {
    Box,
    Flex,
    Button,
    Heading
} from '@chakra-ui/react'
import styled from '@emotion/styled'

const StatBox = styled(Box)`
    font-weight: 700;
    font-family: lato, sans-serif;
    text-align: center;
    margin-bottom: 1rem;
    :nth-child(odd) {
        color: #cc2027;
    }
    //width: 16.666%;
`;

// Using Chakra Factor - sizes aren't working
// const StatNumber = chakra(Heading, {
//     baseStyle: {
//         fontSize: "6rem",
//         fontFamily: "lato, sans-serif",
//         textAlign: "center",
//         marginBottom: "0"
//     },
//     sizes: {
//         md: {
//             fontSize: '2rem'
//         },
//         lg: {
//             fontSize: '4.5rem'
//         },
//     }
// });

// const Stat = chakra("h2", {
//     baseStyle: {
//         fontSize: '3.5rem',
//         fontFamily: 'lato, sans-serif',
//         textAlign: 'center',
//         textTransform: 'uppercase'
//     }
// })

const TeamStats = () => {
  const API = `https://sheets.googleapis.com/v4/spreadsheets/14wncbUF48e4_vqqx-R4LlEUatwgiRf7TS76spQA1lx8/values:batchGet?ranges=2021-2022&majorDimension=ROWS&key=${process.env.GATSBY_GOOGLE_SHEETS}`
    
  const [ teamData, setTeamData ] = useState([]);
  
useEffect(() => {
  fetch(API).then(response => response.json())
  .then(data => {
    let batchRowValues = data.valueRanges[0].values[1];
    setTeamData(batchRowValues);
})
  // console.log(teamData);
  }, []);


    return (
        <>
        <Flex p="4" w="100%" justifyContent="center" flexWrap="wrap" mb="8">
          <StatBox w={{base: '50%', md: '33.33%', lg: '16.66%'}}>
              <Heading fontSize='5rem' variant="stat-number">
                  {teamData[0]}
              </Heading>
              <Heading as="h3" fontSize="2rem" variant="stat" textAlign="center">
                  Wins
              </Heading>
          </StatBox>
          <StatBox w={{base: '50%', md: '33.33%', lg: '16.66%'}}>
              <Heading fontSize='5rem' variant="stat-number">
                  {teamData[1]}
              </Heading>
              <Heading as="h3" fontSize="2rem" variant="stat" textAlign="center">
                  Losses
              </Heading>
          </StatBox>
          <StatBox w={{base: '50%', md: '33.33%', lg: '16.66%'}}>
              <Heading fontSize='5rem' variant="stat-number">
                  {teamData[5]}
              </Heading>
              <Heading as="h3" fontSize="2rem" variant="stat" textAlign="center">
                  Ties
              </Heading>
          </StatBox>
          <StatBox w={{base: '50%', md: '33.33%', lg: '16.66%'}}>
              <Heading fontSize='5rem' variant="stat-number">
                  {teamData[2]}
              </Heading>
              <Heading as="h3" fontSize="2rem" variant="stat" textAlign="center">
                  Goals
              </Heading>
          </StatBox>
          <StatBox w={{base: '50%', md: '33.33%', lg: '16.66%'}}>
              <Heading fontSize='5rem' variant="stat-number">
                  {teamData[3]}
              </Heading>
              <Heading as="h3" fontSize="2rem" variant="stat" textAlign="center">
                  Assists
              </Heading>
          </StatBox>
          <StatBox w={{base: '50%', md: '33.33%', lg: '16.66%'}}>
              <Heading fontSize='5rem' variant="stat-number">
                  {teamData[4]}
              </Heading>
              <Heading as="h3" fontSize="2rem" variant="stat" textAlign="center">
                  Penalties
              </Heading>
          </StatBox>


       </Flex>
            <Box p="2" mb="20" textAlign="center">
                <Button variant="black-button" textTransform="uppercase" isFullWidth as={Link} to="/moose-hockey/stats" size="lg">
                    Full Team Statistics
                </Button>
            </Box>
              </>
    )
}

export default TeamStats